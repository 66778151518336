import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Deadlifts 12-12-12`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 AMRAP of:`}</p>
    <p>{`50-Double Unders`}</p>
    <p>{`15-KB SDHP’s (53/35)`}</p>
    <p>{`10-Burpee DB Deadlifts (40/25)`}</p>
    <p>{`5-Bar Muscle Ups`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will be adding a 9:00am class on Tuesdays and Thursdays starting
Thursday, August 2nd!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Sunday, August 5th we are having our Ville get together at the
Barnetts house, everyone is invited!  We’ll watch the final day of the
CrossFit Games, eat, swim, and we’ll even have a few chances to workout.
 Call, text or email Daniel for the address.  Family friendly so bring
the kids!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      